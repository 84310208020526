export const PastEvents = [
    {
        "date": "14/10/2024-23/10/2024",
        "name": "TechXcelerate 3.0",
        "image": "",
        "participants": "",
        "location": "HYBRID",
        "type": "LEARNATHON",
        "link": "",
    },
    {
        "date": "31/03/2024",
        "name": "Game of Codes 4.0",
        "image": "",
        "participants": "",
        "location": "VIRTUAL",
        "type": "CONTEST",
        "link": "",
    },
    {
        "date": "16/03/2024 - 17/03/2024",
        "name": "HACKHAZARDS '24",
        "image": "./img/events/NSCC_62_Poster.png",
        "participants": "",
        "location": "IN-PERSON",
        "type": "HACKATHON",
        "link": "",
    },
    {
        "date": "09/03/2024",
        "name": "Talk by Anuj Bhaiya",
        "image": "",
        "participants": "",
        "location": "IN-PERSON",
        "type": "MEETUP",
        "link": "",
    },
    {
        "date": "09/03/2024",
        "name": "Talk by Sandeep Jain, Founder GFG",
        "image": "",
        "participants": "",
        "location": "IN-PERSON",
        "type": "MEETUP",
        "link": "",
    },
    {
        "date": "09/03/2024",
        "name": "Pre-Hackathon Meetup",
        "image": "./img/events/NSCC_28-39_main-poster.jpg",
        "participants": "",
        "location": "IN-PERSON",
        "type": "MEETUP",
        "link": "",
    },
    {
        "date": "03/03/2024",
        "name": "Placement Session by Taruvar Mittal",
        "image": "./img/events/NSCC_61_Poster.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WEBINAR",
        "link": "",
    },
    {
        "date": "28/01/2024",
        "name": "Qonnect Community Connect",
        "image": "",
        "participants": "",
        "location": "IN-PERSON",
        "type": "MEETUP",
        "link": "",
    },
    {
        "date": "28/01/2024",
        "name": "Router Protocol Workshop",
        "image": "",
        "participants": "",
        "location": "IN-PERSON",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "28/01/2024",
        "name": "TechXcelerate 2023 Closing Ceremony",
        "image": "./img/events/NSCC_28-39_main-poster.jpg",
        "participants": "",
        "location": "IN-PERSON",
        "type": "MEETUP",
        "link": "",
    },
    {
        "date": "27/01/2024",
        "name": "TechX 2.0: DevOps",
        "image": "./img/events/techx2_8.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "26/01/2024",
        "name": "TechX 2.0: Cyber Security",
        "image": "./img/events/techx2_7.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "25/01/2024",
        "name": "TechX 2.0: Web Development",
        "image": "./img/events/techx2_6.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "24/01/2024",
        "name": "TechX 2.0: AI & ML",
        "image": "./img/events/techx2_5.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "23/01/2024",
        "name": "TechX 2.0: Blockchain",
        "image": "./img/events/techx2_4.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "22/01/2024",
        "name": "TechX 2.0: App Development",
        "image": "./img/events/techx2_3.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "21/01/2024",
        "name": "TechX 2.0: Open Source",
        "image": "./img/events/techx2_2.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "20/01/2024",
        "name": "TechX 2.0: DSA & CP",
        "image": "./img/events/techx2_1.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "20/01/2023-27/01/2023",
        "name": "TechXcelerate 2024",
        "image": "./img/events/NSCC_28-39_main-poster.jpg",
        "participants": "",
        "location": "HYBRID",
        "type": "LEARNATHON",
        "link": "",
    },
    {
        "date": "08/12/2023",
        "name": "Annual Orientation",
        "image": "",
        "participants": "",
        "location": "VIRTUAL",
        "type": "MEETUP",
        "link": "",
    },
    {
        "date": "17/11/2023",
        "name": "Internal Meet",
        "image": "./img/events/NSCC_52_Poster.png",
        "participants": "",
        "location": "IN-PERSON",
        "type": "MEETUP",
        "link": "",
    },
    {
        "date": "22/10/2023",
        "name": "API 101 using Postman",
        "image": "./img/events/NSCC_51_Poster.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "21/10/2023",
        "name": "Game of Codes 3.0",
        "image": "./img/events/NSCC_50_Poster.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "CONTEST",
        "link": "",
    },
    {
        "date": "17/09/2023",
        "name": "SIH Guidance Session",
        "image": "./img/events/NSCC_48_Poster.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WEBINAR",
        "link": "",
    },
    {
        "date": "11/08/2023",
        "name": "Webinar: MBA as a career choice",
        "image": "./img/events/NSCC_47_Poster.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WEBINAR",
        "link": "",
    },
    {
        "date": "03/06/2023",
        "name": "Game of Codes 2.0",
        "image": "./img/events/NSCC_46_Poster.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "CONTEST",
        "link": "",
    },
    {
        "date": "04/05/2023",
        "name": "Seminar by Miss Purva Aggarwal",
        "image": "./img/events/NSCC_45_Poster.jpg",
        "participants": "",
        "location": "IN-PERSON",
        "type": "SEMINAR",
        "link": "",
    },
    {
        "date": "01/05/2023 - 02/05/2023",
        "name": "Freshers' Placement Talk",
        "image": "./img/events/NSCC_43-44_Poster.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "SEMINAR",
        "link": "",
    },
    {
        "date": "29/04/2023",
        "name": "Introduction to Web Development",
        "image": "./img/events/NSCC_42_Poster.jpeg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WEBINAR",
        "link": "",
    },
    {
        "date": "25/04/2023",
        "name": "Webinar on Google Generation Scholarship",
        "image": "./img/events/NSCC_41_Poster.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WEBINAR",
        "link": "",
    },
    {
        "date": "23/04/2023",
        "name": "TechX '23 - AR/VR/XR",
        "image": "./img/events/techx12.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "23/04/2023",
        "name": "TechX '23 - Mobile App Development",
        "image": "./img/events/techx11.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "22/04/2023",
        "name": "TechX '23 - UI/UX",
        "image": "./img/events/techx10.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "22/04/2023",
        "name": "TechX '23 - Web Development",
        "image": "./img/events/techx9.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "21/04/2023",
        "name": "TechX '23 - Open Source",
        "image": "./img/events/techx8.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "21/04/2023",
        "name": "TechX '23 - Cloud and DevOps",
        "image": "./img/events/techx7.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "14/04/2023",
        "name": "TechX '23 - DS",
        "image": "./img/events/techx6.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "16/04/2023",
        "name": "TechX '23 - AI and ML",
        "image": "./img/events/techx5.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "15/04/2023",
        "name": "TechX '23 - Blockchain and Web3.0",
        "image": "./img/events/techx4.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "15/04/2023",
        "name": "TechX '23 - Cyber Security",
        "image": "./img/events/techx3.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "14/04/2023",
        "name": "TechX '23 - CP",
        "image": "./img/events/techx2.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "14/04/2023",
        "name": "TechX '23 - DSA",
        "image": "./img/events/techx1.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "14/04/2023",
        "name": "TechXcelerate 2023",
        "image": "./img/events/NSCC_28-39_main-poster.jpg",
        "participants": "",
        "location": "HYBRID",
        "type": "LEARNATHON",
        "link": "",
    },
    {
        "date": "04/04/2023",
        "name": "Interview Preparation Bootcamp",
        "image": "./img/events/NSCC_27_Poster.jpeg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "BOOTCAMP",
        "link": "",
    },
    {
        "date": "22/03/2023",
        "name": "Coderush March Edition",
        "image": "./img/events/NSCC_26_Poster.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "CONTEST",
        "link": "",
    },
    {
        "date": "28/01/2023",
        "name": "Coderush Coding Contest - January",
        "image": "./img/events/nsev25.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "CONTEST",
        "link": "",
    },
    {
        "date": "11/01/2023",
        "name": "Podcast on Competitive Programming",
        "image": "./img/events/nsev24.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WEBINAR",
        "link": "",
    },
    {
        "date": "07/01/2023",
        "name": "Web Dev Bootcamp",
        "image": "./img/events/nsev23.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "BOOTCAMP",
        "link": "",
    },
    {
        "date": "06/01/2023",
        "name": "HackNITR 4.0 Community Partnership",
        "image": "./img/events/nsev22.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "PARTNERSHIP",
        "link": "",
    },
    {
        "date": "03/01/2023",
        "name": "DSA Bootcamp",
        "image": "./img/events/nsev21.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "BOOTCAMP",
        "link": "",
    },
    {
        "date": "28/12/2022",
        "name": "GeekTalk",
        "image": "./img/events/nsev20.jpg",
        "participants": "",
        "location": "IN-PERSON",
        "type": "SEMINAR",
        "link": "",
    },
    {
        "date": "27/12/2022",
        "name": "Meet & Greet",
        "image": "./img/events/nsev19.png",
        "participants": "",
        "location": "IN-PERSON",
        "type": "MEETUP",
        "link": "",
    },
    {
        "date": "20/12/2022",
        "name": "Data Science Masterclass",
        "image": "./img/events/nsev18.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "17/12/2022",
        "name": "Cracking GSoC and other fellowships",
        "image": "./img/events/nscc4.jpeg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WEBINAR",
        "link": "",
    },
    {
        "date": "11/12/2022",
        "name": "Annual Orientation",
        "image": "./img/events/nscc6.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "MEETUP",
        "link": "",
    },
    {
        "date": "07/12/2022",
        "name": "Arrays Masterclass",
        "image": "./img/events/nsev15.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "01/12/2022",
        "name": "DSA Masterclass",
        "image": "./img/events/nsev14.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "30/11/2022",
        "name": "JavaScript Bootcamp",
        "image": "./img/events/nscc5.png",
        "location": "VIRTUAL",
        "type": "BOOTCAMP",
        "link": "",
    },
    {
        "date": "30/11/2022",
        "name": "Coding Challange - November",
        "image": "./img/events/nsev12.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "CONTEST",
        "link": "",
    },
    {
        "date": "25/11/2022",
        "name": "Open Source Webinar",
        "image": "./img/events/nsev11.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WEBINAR",
        "link": "",
    },
    {
        "date": "21/11/2022",
        "name": "Interview Masterclass",
        "image": "./img/events/nsev10.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "16/11/2022",
        "name": "Reskill Appathon",
        "image": "./img/events/nsev9.png",
        "participants": "",
        "location": "HYBRID",
        "type": "PARTNERSHIP",
        "link": "",
    },
    {
        "date": "14/11/2022",
        "name": "GitHub Universe Watch Party",
        "image": "./img/events/nscc8.png",
        "participants": "",
        "location": "VIRTUAL",
        "type": "WORKSHOP",
        "link": "",
    },
    {
        "date": "27/10/2022",
        "name": "Code to Celebrate - Coding Contest",
        "image": "./img/events/nsev7.jpg",
        "participants": "",
        "location": "VIRTUAL",
        "type": "CONTEST",
        "link": "",
    },
    {
        "date": "21/10/2022",
        "name": "Game of Codes 1.0",
        "image": "./img/events/nsev6.png",
        "location": "VIRTUAL",
        "type": "CONTEST",
        "link": "",
    },
    {
        "date": "16/10/2022",
        "name": "Webinar on Design",
        "image": "./img/events/nsev5.jpg",
        "location": "VIRTUAL",
        "type": "WEBINAR",
        "link": "",
    },
    {
        "date": "11/10/2022",
        "name": "UI/UX Bootcamp",
        "image": "./img/events/nsev4.png",
        "location": "VIRTUAL",
        "type": "BOOTCAMP",
        "link": "",
    },
    {
        "date": "08/10/2022",
        "name": "Open Source and Git Webinar",
        "image": "./img/events/nsev3.png",
        "location": "VIRTUAL",
        "type": "WEBINAR",
        "link": "",
    },
    {
        "date": "28/09/2022",
        "name": "Coding Contest - September",
        "location": "VIRTUAL",
        "type": "CONTEST",
        "link": "",
    },
    {
        "date": "21/09/2022",
        "name": "CodeChef College Program",
        "location": "HYBRID",
        "type": "LEARNATHON",
        "link": "",
    }
];
